//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import Plugin from '@quasar/quasar-ui-qoverlay'
import '@quasar/quasar-ui-qoverlay/dist/index.css'
Vue.use(Plugin)

export default {
  name: 'FeedbackDialog',
  data() {
    return {
      userName: '',
      email: '',
      message: '',
      error: '',
      overlay: false
    }
  },
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onDialogHide() {
      this.$emit('hide')
    },
    async onOKClick() {
      this.overlay = true
      try {
        await this.$store.getters.apiRequest('post', '/v1/feedback', {
          name: this.userName,
          email: this.email,
          message: this.message
        })
      } catch (err) {
        this.overlay = false
        this.error = 'Failed to send message. Try again?'
        return
      }
      this.$emit('ok')
      this.hide()
    },
    onCancelClick() {
      this.hide()
    }
  },
  mounted() {
    if (this.$store.state.user.userData) {
      this.userName = this.$store.state.user.userData.name
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import FeedbackDialog from '../components/util/FeedbackDialog.vue'

export default {
  name: 'MoreDetails',
  computed: mapGetters(['authUrl']),
  methods: {
    showFeedbackDialog() {
      this.$q.dialog({
        component: FeedbackDialog,
        parent: this
      })
    },
    login() {
      sessionStorage.loginRetried = 'no'
      window.location.href = this.authUrl
    }
  }
}
